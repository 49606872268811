import React from 'react';
import '../styles/Header.css';
import headerImage from '../assets/douro1.jpg'; // Update the path to your image file
function Header() {
  return (
    <header className="header">
      <div className="header-content">
        {/* <img src={headerImage} alt="Header Image" className="header-image" /> */}
        <h1 className="header-text">FERRONHA'S</h1>
        <p className="header-description">É um vinho único, nascido de uma colheita familiar situada numa pequena aldeia na margem sul do rio Douro. Elaborado com a combinação das melhores castas durienses de vinhas velhas.</p>
      </div>
    </header>
  );
}

export default Header;
