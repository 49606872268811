import React, { useState } from 'react';
import '../styles/Navbar.css';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">FERRONHA'S</div>

      {/* Conditionally render the menu icon based on screen size */}
      <div className={`navbar-menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
        <div className="icon-bar">Home</div>
        <div className="icon-bar">About</div>
        <div className="icon-bar">Contact</div>
      </div>

      <ul className={`navbar-list ${isMenuOpen ? 'open' : ''}`}>
        <li className="navbar-item">Home</li>
        <li className="navbar-item">About</li>
        <li className="navbar-item">Contact</li>
      </ul>
    </nav>
  );
}

export default Navbar;
