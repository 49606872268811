import React, { useState } from "react";
import AgeVerificationModal from "./components/AgeVerificationModal";
import "./App.css";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Product from "./components/Product";
import product1 from '../src/assets/garrafa1.jpg';
import product2 from '../src/assets/rotulo1.jpg';
import product3 from '../src/assets/ferronhas_2019.jpg';
import product4 from '../src/assets/ferronhas_2021.jpg';

function App() {
  const [ageVerified, setAgeVerified] = useState(false);

  const handleVerification = () => {
    setAgeVerified(true);
  };

  return (
    <div className="App">
      <Navbar />
      <Header />
      <Product
          title={"Grande Reserva Tinto 2019"}
          images={[ product3]}
      />  
      <Product
        title={"Grande Reserva Tinto 2019"}
        images={[ product4]}
      />
      {/* {ageVerified ? (
        <Product
          title={"Grande Reserva Vinho Tinto 2019"}
          images={[product1, product2]}
        />
      ) : (
        <AgeVerificationModal onVerification={handleVerification} />
      )} */}
      <Footer />
    </div>
  );
}

export default App;
