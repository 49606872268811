import React, { useState } from 'react';
import '../styles/Product.css';


function Product({ title, description, price, rating, stock, images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="product">
      <h3 className="product-title">{title}</h3>
  
      <div className="product-image-container">
        <img className="product-image" src={images[currentImageIndex]} alt="Product Image" onClick={handleImageClick} />
      </div>
  
      <div className="button-container">
        <button className="prev-button" onClick={handlePreviousImage}>
          Previous
        </button>
        <button className="next-button" onClick={handleNextImage}>
          Next
        </button>
      </div>
  
      <div className="product-info">
        <p className="product-description">{description}</p>
        {/* Additional product information */}
      </div>
  
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <img className="modal-image" src={images[currentImageIndex]} alt="Product Image" />
            <span className="modal-close" onClick={handleCloseModal}>
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Product;
